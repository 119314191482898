import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { Button, ButtonVariant } from "@roadflex/react-components-www";
import getConfig from "next/config";
import Image from "next/image";
import router from "next/router";
import FraudPreventionAnimation from "../../../../components/common-components/fraud-prevention-animation/fraud-prevention-animation";

type FraudPreventionWrapperProps = {
  title: string;
  subtitle: string;
  helper: string;
  showCards?: boolean;
  showCardImages?: boolean;
};
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
export default function FraudPreventionWrapper({
  title,
  subtitle,
  helper,
  showCards = false,
  showCardImages = false,
}: FraudPreventionWrapperProps) {
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };
  return (
    <div className="flex items-center justify-center w-full bg-cover bg-panel-background">
      <div className="container w-full space-y-14 md:space-y-24 my-14 md:my-32">
        <div className="text-white ">
          <FraudPreventionAnimation
            title={title}
            subtitle={subtitle}
            helper={helper}
          />
          {showCards && (
            <div className="flex flex-col items-center justify-center mx-auto gap-14 md:gap-8 xl:gap-12 mt-14 md:mt-32 md:items-stretch md:flex-row">
              <div className="overflow-hidden bg-[#633C2B] flex flex-col rounded-2xl p-8 md:p-10 shadow-md max-w-[550px] w-full">
                <div className="flex flex-col justify-between h-full gap-4 flex-grow-1">
                  <div className="text-xl font-semibold md:text-3xl">
                    Real-time alerts and data for better control
                  </div>
                  <div className="flex flex-row flex-wrap items-center justify-between">
                    Receive real-time purchase notifications and block
                    suspicious transactions from happening. You don&apos;t have
                    to wait weeks before being able to identify suspicious
                    transactions anymore.
                  </div>
                  <Button
                    variant={ButtonVariant.Transparent}
                    className="!pl-0 !w-fit md:ml-0 text-orange-500 animate-pulse"
                    onClick={() => {
                      redirectTo("security-features");
                    }}
                  >
                    Learn More{" "}
                    <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                  </Button>
                  {showCardImages && (
                    <div className="relative">
                      <Image
                        layout="responsive"
                        width={"643"}
                        height={"440"}
                        className=""
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/discount-tire/real-time-alerts.png`}
                        alt="Real time alerts"
                      ></Image>
                    </div>
                  )}
                </div>
              </div>
              <div className="overflow-hidden bg-[#633C2B] flex flex-col rounded-2xl p-8  md:p-10 shadow-md max-w-[550px] w-full">
                <div className="flex flex-col justify-between h-full gap-4 flex-grow-1">
                  <div className="text-xl font-semibold md:text-3xl">
                    Automatically validate vehicle location and fuel level at
                    the time of purchase
                  </div>
                  <div className="flex flex-row flex-wrap items-center justify-between">
                    Automatically verify the card and the vehicle are at the
                    same location before the transaction is approved.
                    Automatically block purchases when the vehicle isn&apos;t
                    near the purchase location or when fuel transaction exceeds
                    fuel tank level.
                  </div>
                  <Button
                    variant={ButtonVariant.Transparent}
                    className="!pl-0 !w-fit md:ml-0 text-orange-500 animate-pulse"
                    onClick={() => {
                      redirectTo("security-features");
                    }}
                  >
                    Learn More{" "}
                    <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                  </Button>
                  {showCardImages && (
                    <div className="relative">
                      <Image
                        layout="responsive"
                        width={"634"}
                        height={"374"}
                        className=""
                        src={`${NEXT_PUBLIC_CDN_URL}/partners/images/discount-tire/authorization-validations.png`}
                        alt="Authorization Validations"
                      ></Image>
                    </div>
                  )}
                </div>
                {/* <div className="relative mt-8">
                <Image
                  src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fuel-level-theft-alert.png`}
                  priority={true}
                  layout="responsive"
                  width={750}
                  height={500}
                  alt="blockquote"
                />
              </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
