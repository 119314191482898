/** @format */
import { LANDING_PAGE_LEGACY_FUEL_MANAGEMENT } from "@roadflex/constants";
import AlternateComponent from "../../common-components/alternate";

interface Panel5 {
  isScheduleDemo?: boolean;
}
export default function Panel5({ isScheduleDemo = true }: Panel5) {
  return (
    <div id="panel-5" className="w-full h-full bg-darkblue">
      <div className="flex items-center justify-center w-full text-brown-900 my-14 md:my-32">
        <div className="container w-full">
          <div className="mb-16">
            <div className="mb-8 text-3xl font-bold text-center text-orange-500 md:text-4xl 3xl:text-5xl">
              Your Legacy Fuel Management Is Costly and Burdensome
            </div>
            <div className="container flex flex-col justify-center gap-10 lg:flex-row">
              <div className="grid order-2 grid-cols-1 gap-3 text-sm sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 lg:order-1">
                <div className="flex flex-col items-center text-center w-full max-w-[380px]">
                  <div className="static -rotate-6">
                    <div className="relative">
                      <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                      <div className="relative bg-white rounded-lg shadow-sm min-h-[240px] p-8 m-[2px]">
                        <div className="mt-8 text-lg font-semibold text-orange-500 md:text-xl">
                          For fleet operators and owners
                        </div>
                        <div className="mt-4 text-base">
                          are you tired of fuel fraud, card misuse and
                          unresponsive customer service?
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center text-center w-full max-w-[380px]">
                  <div className="static">
                    <div className="relative">
                      <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                      <div className="relative bg-white rounded-lg shadow-sm min-h-[240px] p-8 m-[2px]">
                        <div className="mt-8 text-lg font-semibold text-orange-500 md:text-xl">
                          For controllers and finance teams
                        </div>
                        <div className="mt-4 text-base">
                          are you tired of excessive hidden fees, and tedious
                          manual fuel reporting?
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center text-center w-full max-w-[380px]">
                  <div className="static rotate-6">
                    <div className="relative">
                      <div className="absolute inset-0 blur from-orange-500 via-brown-500 to-yellow-500 bg-gradient-to-br"></div>
                      <div className="relative bg-white rounded-lg shadow-sm min-h-[240px] p-8 m-[2px]">
                        <div className="mt-8 text-lg font-semibold text-orange-500 md:text-xl">
                          For drivers
                        </div>
                        <div className="mt-4 text-base">
                          are you tired of getting stuck at the pump or not
                          having a payment option for emergencies?
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlternateComponent
        features1={LANDING_PAGE_LEGACY_FUEL_MANAGEMENT}
        backgroundColor="darkblue"
        icon="CheckCircleIcon"
        isScheduleDemo={isScheduleDemo}
      />
    </div>
  );
}
